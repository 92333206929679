import * as React from "react";

export interface LaviniaSVGLogoProps {
    title: string;
}

export class LaviniaSVGLogo extends React.Component<LaviniaSVGLogoProps, {}> {
    render() {
        const { title } = this.props;
        return (
            <div className="lavinia-logo">
                <svg width="100%" height="100%" viewBox="0 0 164 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <text fontSize="26" x="54" y="34">
                        {title}
                    </text>
                    <g transform="translate(-32.831 -75.101)">
                        <g>
                            <g>
                                <path d="m35.364 81.039h42.095l-20.904-5.6012z" />
                                <path d="m35.364 84.179h41.961l0.93305 0.5387v2.4013l-0.7672 0.7672h-42.06l-0.7845-0.78451v-2.2545z" />
                                <path d="m38.638 88.99h-3.4745v1.0691l1.0622 0.61327h1.5437l0.96713-0.55838z" />
                                <path d="m44.398 88.951h-3.4745v1.0691l1.0622 0.61327h1.5437l0.96713-0.55838z" />
                                <path d="m49.943 88.951h-3.4745v1.0691l1.0622 0.61327h1.5437l0.96713-0.55838z" />
                                <path d="m55.503 88.94h-3.4745v1.0691l1.0622 0.61327h1.5437l0.96713-0.55838z" />
                                <path d="m61.052 88.94h-3.4745v1.0691l1.0622 0.61327h1.5437l0.96713-0.55838z" />
                                <path d="m66.724 88.95h-3.4745v1.0691l1.0622 0.61327h1.5437l0.96713-0.55838z" />
                                <path d="m72.22 88.95h-3.4745v1.0691l1.0622 0.61327h1.5437l0.96713-0.55838z" />
                                <path d="m77.853 88.95h-3.4745v1.0691l1.0622 0.61327h1.5437l0.96713-0.55838z" />
                                <path d="m37.591 91.807 0.93018 21.911h-0.49093v0.46509h-2.2479v-0.43925h-0.43925l0.93018-21.937z" />
                                <path d="m43.305 91.807 0.93018 21.911h-0.49093v0.46509h-2.2479v-0.43925h-0.43925l0.93018-21.937z" />
                                <path d="m48.826 91.781 0.93018 21.911h-0.49093v0.46509h-2.2479v-0.43925h-0.43925l0.93018-21.937z" />
                                <path d="m54.45 91.755 0.93018 21.911h-0.49093v0.46509h-2.2479v-0.43925h-0.43925l0.93018-21.937z" />
                                <path d="m60.105 91.755 0.93018 21.911h-0.49093v0.46509h-2.2479v-0.43925h-0.43925l0.93018-21.937z" />
                                <path d="m65.681 91.755 0.93017 21.911h-0.49093v0.46509h-2.2479v-0.43925h-0.43925l0.93018-21.937z" />
                                <path d="m71.177 91.703 0.93018 21.911h-0.49093v0.46509h-2.2479v-0.43925h-0.43925l0.93018-21.937z" />
                                <path d="m76.754 91.755 0.93018 21.911h-0.49093v0.46509h-2.2479v-0.43925h-0.43925l0.93018-21.937z" />
                                <path d="m79.277 119.42-45.891 8e-3s-1.1693-0.4933 0.38368-3.2521l45.124-8e-3 0.26458-1.9e-4c1.8176 2.7586 0.38368 3.2523 0.38368 3.2523z" />
                                <path d="m34.226 83.079-0.54811-0.54811 0.59379-0.59379h44.45l0.54811 0.54811-0.59378 0.59379z" />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}
